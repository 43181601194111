<template>
  <div class="page-home">
    <div class="home-main">
      <div class="home-title">
        排队序号
        <span>
          <router-link :to="{'name':'rule.index'}">
            <img src="../../assets/explain.png">
          </router-link>
        </span>
      </div>
      <div class="home-number">{{ result.number }}</div>
      <div v-if="status === '1'">
        <div class="info">您前方还有 <span>{{ result.before_count }}</span> 位在排队</div>
        <div class="result">&nbsp;</div>
      </div>
      <div v-if="status === '2'">
        <div class="info-Windows"> {{ result.window }} </div>
        <div class="result wait">等待办理 </div>
      </div>
      <div v-if="status === '3'">
        <div class="info-Windows"> {{ result.window }} </div>
        <div class="result finish">已完成</div>
      </div>
      <van-row class="get-time">
        <van-col span="6" class="time">取号时间:</van-col>
        <van-col span="18" class="time time_grey"> {{ result.dt_add }}</van-col>
      </van-row>

      <van-row class="use-time">
        <van-col span="6" class="time">已等待:</van-col>
        <van-col span="18" class="time time_grey"> {{ waittime }}</van-col>
      </van-row>
    </div>
    <div class="number-box">
      <div class="number-title">
        {{ result.input_title }}
      </div>
      <div class="number-info">{{ result.input }}</div>
    </div>
    <div class="home_explain">{{ result.tips }}</div>
    <div class="btn">
      <div v-if="status != '3'">
        <van-button type="info" color="#106FFF" size="large" @click.native="onCancel">放弃排号</van-button>
      </div>
    </div>
    {{ avatar }}
  </div>
</template>

<script>
import { getNumberStatus, giveup } from '@/api/data'
import { getBranch } from '@/utils/auth'
import { mapGetters } from 'vuex'
import { Button, Empty, Col, Row, Dialog, Toast } from 'vant'

export default {
  inject: ['routerRefresh'], // 在子组件中注入在父组件中创建的属性
  name: 'HomeIndex',
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Col.name]: Col,
    [Row.name]: Row,
    [Dialog.name]: Dialog,
    [Toast.name]: Toast
  },
  data() {
    return {
      logo: require('../../assets/logo.png'),
      status: 1,
      timer: '',
      listTimer: '',
      value: 0,
      datetime: '',
      waittime: '',
      result: '',
      sysset_id: '',
      listQuery: {},
      sysset_data: '',
      branch: getBranch()
    }
  },
  computed: {
    avatar() {
      return this.userInfo ? this.userInfo.avatar : this.logo
    },
    introduction() {
      return this.userInfo ? this.userInfo.introduction : '南京蓝牛软件有限公司'
    },
    ...mapGetters([
      'userInfo',
      'branchInfo'
    ])
  },
  mounted() {
    this.timer = setInterval(this.waitTime, 1000)
    this.listTimer = setInterval(this.getStatus, 10000)
  },
  created() {
    if (this.$route.query.sysset_id) {
      this.sysset_id = this.$route.query.sysset_id
      this.getStatus()
    }
    if (this.branch) {
      this.sysset_data = JSON.parse(this.branch)
    }
  },
  methods: {
    getStatus() {
      getNumberStatus({ 'sysset_id': this.sysset_id }).then((response) => {
        this.result = response.data.data
        this.status = response.data.data.status
        this.datetime = response.data.data.dt_add
      }).catch((err) => {
        console.log(err)
        clearInterval(this.listTimer)
        this.listTimer = ''
        this.$router.push({ name: 'home.index' })
        this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
      })
    },
    onCancel() {
      Dialog.confirm({
        title: '您确定要放弃排号吗？',
        message: '频繁无故放弃排号，将会被列入\n黑名单用户',
        confirmButtonColor: '#106FFF',
        cancelButtonColor: '#101112',
        className: 'digstyle'
      }).then(() => {
        this.listQuery.id = this.result.id
        this.listQuery.sysset_id = this.sysset_id
        giveup(this.listQuery).then((response) => {
          clearInterval(this.listTimer)
          this.listTimer = ''
          Toast({
            type: 'success',
            message: '放弃成功',
            forbidClick: true,
            onClose: () => {
              this.$router.push({ name: 'home.index', query: { sysset_id: this.sysset_id }})
              this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
            }
          })
        })
      })
    },
    waitTime() {
      var startTime = new Date(this.datetime.replace(/-/g, '/')) // 开始时间
      var endTime = new Date() // 结束时间
      var usedTime = endTime - startTime // 相差的毫秒数
      var leavel = usedTime % (24 * 3600 * 1000) // 计算天数后剩余的时间
      var leavel2 = leavel % (3600 * 1000) // 计算剩余小时后剩余的毫秒数
      var house = Math.floor(leavel / (60 * 60 * 1000)) // 计算剩余的分钟数
      var minutes = Math.floor(leavel2 / (60 * 1000)) // 计算剩余的分钟数
      this.waittime = house + '小时' + minutes + '分钟'
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = ''
      clearInterval(this.listTimer)
      this.listTimer = ''
    }
  }
}
</script>

<style lang="less" scoped>
  .page-home{width: 100%;float: left; height: calc(100vh);background: #F6F7FB;}
  .home-main{width: 90%;float: left; margin: 5% 5% 0 5%;padding:10px 5%; height: calc(38vh);background: #FFFFFF;border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;}
  .home-title{width: 100%;font-size: 16px;float: left;height: calc(5vh); line-height: calc(5vh); font-weight: bold; }
  .home-title span{float: right;}
  .home-title span img{width: 16px;}
  .home-number{width: 100%;float: left;height: calc(12vh);line-height: calc(12vh);font-size: 54px;font-weight: bold; text-align: center; color: #106FFF; }
  .info{width: 100%;float: left;height: calc(4vh);line-height: calc(4vh);font-size: 14px;text-align: center;}
  .info span{font-size: 20px;color: #106FFF;margin: 0 5px;}
  .info-Windows{width: 100%;float: left;height: calc(4vh);line-height: calc(4vh);font-size: 15px;text-align: center;font-weight: bold;color: #106FFF}
  .result{width: 100%;float: left;height: calc(4vh);line-height: calc(4vh); text-align: center;font-size: 14px;font-weight: bold;}
  .wait{background: #FFD9A1;color: #FFA013;}
  .finish{background: #F6F7FB;color: #8993A8}
  .get-time{width: 100%;float: left;height: calc(5vh);line-height: calc(5vh);border-top: 1px solid #F6F7FB; }
  .use-time{width: 100%;float: left;height: calc(5vh); line-height: calc(5vh);}
  .time{text-align: right;color: #101112;font-size: 14px;font-weight: bold;}
  .time_grey{color: #8993A8;font-weight: normal;}
  .home_explain{width: 90%;float: left; margin: 10px 5% 0 5%; height: calc(5vh); word-break: break-all;line-height:1.5em;font-size: 14px;text-align: left;color: #106FFF}
  .btn{width: 90%;float: left;height: 54px; margin: 100px 5% 0 5%;}
  /deep/ .van-dialog__header{color: #101112;font-size: 16px;font-weight: bold}
  /deep/ .van-dialog__message{color: #f00}
  .number-box{width: 90%;float: left; margin: calc(1vh) 5% calc(1vh) 5%;padding:0px 5%; height: calc(12vh);background: #FFFFFF;border-radius:10px;font-size: 15px;}
  .number-title{width: 100%;font-size: 15px;float: left;height: calc(5vh); line-height: calc(5vh); font-weight: bold; color: #8993A8; }
.number-info{width: 100%;height: calc(5vh);display: flex;align-items: center;justify-content: center;color: #101112;font-weight: bold;font-size: 18px; }
</style>
